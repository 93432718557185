
import React, { Component } from 'react';
import styles from './ProgressTherapy.module.sass';
import {ReactComponent as ReactSvg} from './therapy-graph.svg';
import PathContext from "../../context/path";
import { gsap } from "gsap";
class ProgressTherapy extends Component {
	

	state={
		opened: false,
		stages: 2,
		step: 49
	};
	stage = 0;
	refGraph= React.createRef();
	refText= React.createRef();
	refBText= React.createRef();
	static contextType = PathContext;
	path = this.context;
	data = [
		{
			"stage": 0,
			"btext": "Без терапии",
			"text": ["ВИЧ-инфекция течет медленно и почти бессимптомно. 8-10 лет ВИЧ размножается, убивая клетки иммунитета. Через 10-15 лет имунные клетки достигают критического минимума и наступает последняя стадия ВИЧ— СПИД. Но только в том случае, если пациент, получив вирус, не начал лечение."]
		},
		{
			"stage": 1,
			"btext": "С АРТ-терапией",
			"text": ["Как только человек начинает принимать терапию, количество клеток вируса падает, а количество клеток иммунитета CD4, наоборот, растёт и восстанавлива-ется почти до прежнего уровня."]
		},
		{
			"stage": 2,
			"btext": "С АРТ-терапией",
			"text": ["Это значит, что вирус в организме есть, но его количество ничтожно мало. Человеку на этот момент ничего не угрожает.","Всемирная организация здравоохранения признала принцип «Неопределяемый = Непередающийся», который подтверждает: люди, принимающие терапию без пропусков, не могут передать ВИЧ через секс."]
		},
		
	];
	
	tl = gsap.timeline({ paused: true });
	graphText1 = null;
	graphText2 = null;
	graphText3 = null;
	graphText4 = null;
	graphText5 = null;
	graphText6 = null;
	graphFill = null;
	graphScaleX1 = null;
	graphScaleX2 = null;
	graphScaleY2 = null;
	graphScaleY1 = null;
	graphScaleXPath1 = null;
	graphScaleXPath2 = null;
	graphDates = null;
	graphScaleDate = null;
	graphBlueLine1 = null;
	graphBlueLine2 = null;
	graphYellowLine1 = null;
	graphYellowLine2 = null;
	text = this.data[0].text;
	btext = this.data[0].btext;
	componentDidMount(){
		this.stage = Math.round(this.props.progress / this.state.step);
		this.setStage = this.setStage.bind(this);
		 window.addEventListener('scroll', this.setStage);


		 this.graphText1 = this.refGraph.current.querySelector('#text1');
		 this.graphText2 = this.refGraph.current.querySelector('#text2');
		 this.graphText3 = this.refGraph.current.querySelector('#text3');
		 this.graphText4 = this.refGraph.current.querySelector('#text4');
		 this.graphText5 = this.refGraph.current.querySelector('#text5');
		 this.graphText6 = this.refGraph.current.querySelector('#text6');
		 this.graphText7 = this.refGraph.current.querySelector('#text7');
		 this.graphFill = this.refGraph.current.querySelector('#fill');
		 this.graphScaleX1 = this.refGraph.current.querySelector('#scalex_path1_1');
		 this.graphScaleX2 = this.refGraph.current.querySelector('#scalex_path1_2');
		 this.graphScaleY1 = this.refGraph.current.querySelector('#scaley1');
		 this.graphScaleY2 = this.refGraph.current.querySelector('#scaley2');
		 this.graphDates = this.refGraph.current.querySelector('#dates');
		 this.graphBlueLine1 = this.refGraph.current.querySelector('#blue_line1');
		 this.graphBlueLine2 = this.refGraph.current.querySelector('#blue_line2');
		 this.graphYellowLine1 = this.refGraph.current.querySelector('#yellow_line1');
		 this.graphYellowLine2 = this.refGraph.current.querySelector('#yellow_line2');
		 this.tl.to(this.graphText4, { duration: 0.1, opacity: 0, x: 25, y: 5 })
			 .to(this.graphText5, { duration: 0.1, opacity: 0, x: 25, y: 5 }, "-=0.1")
			 .to(this.graphText6, { duration: 0.1, opacity: 0, x: 35, y: 5 }, "-=0.1")
			 .to(this.graphText7, { duration: 0.1, opacity: 0, x: 10, y: 270 }, "-=0.1")
			 .to(this.graphFill, { duration: 0.1, opacity: 0 }, "-=0.1")
			 .add('slide-0')
			 .to(this.graphText1, { duration: 0.5, ease: 'power2.inOut', opacity: 0 })
			 .to(this.graphText2, { duration: 0.5, ease: 'power2.inOut', opacity: 0 }, "-=0.5")
			 .to(this.graphText3, { duration: 0.5, ease: 'power2.inOut', opacity: 0 }, "-=0.5")
			 .to(this.graphText4, { duration: 0.5, ease: 'power2.inOut', opacity: 1 }, "-=0.5")
			 .to(this.graphText5, { duration: 0.5, ease: 'power2.inOut', opacity: 1 }, "-=0.5")
			 .to(this.graphBlueLine1, { duration: 1, ease: 'power2.inOut', opacity: 0.2 }, "-=0.5")
			 .to(this.graphYellowLine1, { duration: 1, ease: 'power2.inOut', opacity: 0.2 }, "-=1")
			 .to(this.graphBlueLine2, { duration: 1, ease: 'power2.inOut', attr: { d: 'M185.565 124.471C202.476 116.848 191.816 234.976 210 242.5C224.5 248.5 330.5 248.5 330.5 248.5' } }, "-=1")
			 .to(this.graphYellowLine2, { duration: 1, ease: 'power2.inOut', attr: { d: 'M185.565 223C239 225 181.5 129.5 328 129.5' } }, "-=1")
			 .to(this.graphScaleY1, { duration: 1, ease: 'power2.inOut', opacity: 0 }, "-=1")
			 .to(this.graphDates, { duration: 1, ease: 'power2.inOut', opacity: 0 }, "-=1")
			 .to(this.graphScaleY2, { duration: 1, ease: 'power2.inOut', x: -65 }, "-=1")
			 .add('slide-1')
			 .to(this.graphText4, { duration: 0.5, ease: 'power2.inOut', opacity: 0 })
			 .to(this.graphText5, { duration: 0.5, ease: 'power2.inOut', opacity: 0 }, "-=0.5")
			 .to(this.graphText6, { duration: 0.5, ease: 'power2.inOut', opacity: 1 }, "-=0.5")
			 .to(this.graphText7, { duration: 0.5, ease: 'power2.inOut', opacity: 1 }, "-=0.5")
			 .to(this.graphBlueLine1, { duration: 1, ease: 'power2.inOut', opacity: 0 }, "-=0.5")
			 .to(this.graphYellowLine1, { duration: 1, ease: 'power2.inOut', opacity: 0 }, "-=1")
			 .to(this.graphBlueLine2, { duration: 1, ease: 'power2.inOut', attr: { d: 'M10 124.352C28.5 124.352 4.5 235.854 37.5 242C69.6145 247.982 319.5 256 319.5 256' } }, "-=1")
			 .to(this.graphYellowLine2, { duration: 1, ease: 'power2.inOut', attr: { d: 'M10 222.969C93.5 222.969 1.00916 130 325 130' } }, "-=1")
 
			 .to(this.graphScaleY2, { duration: 1, ease: 'power2.inOut', x: -225 }, "-=1")
			 .to(this.graphScaleX2, { duration: 1, ease: 'power2.inOut', opacity: 0 }, "-=1")
			 .to(this.graphScaleX1, { duration: 1, ease: 'power2.inOut', scaleX: 1.02 }, "-=1")
			 .to(this.graphFill, { duration: 1, ease: 'power2.inOut', opacity: 0.1 })
			 .add('slide-2');

		this.tl.seek('slide-0');
		this.setState({});
  	}
	componentWillUnmount(){
		window.removeEventListener('scroll', this.setStage, false);
		
	}
	
	checkData(stage){
		
		if(this.data[this.stage].text !== this.data[stage].text){
			
			
			
			this.refText.current.style.opacity = 0;
			setTimeout(()=>{
				this.text = this.data[stage].text;
				this.setState({});
				this.refText.current.style.opacity = 1;
			}, 300);
		}
		if(this.data[this.stage].btext !== this.data[stage].btext){
			
			
			
			this.refBText.current.style.opacity = 0;
			setTimeout(()=>{
				this.btext = this.data[stage].btext;
				this.setState({});
				this.refBText.current.style.opacity = 1;
			}, 300);
		}
		this.tl.tweenTo(`slide-${stage}`);
	}
	setStage(){
		let stage = Math.round(this.props.progress / this.state.step);
		
		
		if(stage <= (this.state.stages)){
			
			this.checkData(stage);
			this.stage = stage;
		}
		
	}
	render() {
		
		return (
					<div className={styles.inner} >
						<div className={styles.bg}>
							<img src={`${this.path}/img/therapy-progress-bg.png`} alt="" />
						</div>
					<div className="container">
						<div className={styles.title}  data-aos="fade-up">Развитие <span> ВИЧ-нагрузки</span> <span>и CD4 лимфоцитов</span>  </div>
						
						<ReactSvg ref={this.refGraph} className={styles.svg} />
						<div className={styles.info}>
							<div ref={this.refBText} className={styles.btext}>
								{this.btext}
							</div>
							<div ref={this.refText} className={styles.text}>
								{this.text}
							</div>
						</div>
					</div>
				</div>
			
		);
	}
}

export default ProgressTherapy;
