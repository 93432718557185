import React, { Component } from 'react';

// import Circles from '../../components/Circles';
import FooterAbout from '../../components/FooterAbout';
import PathContext from "../../context/path";
import styles from './About.module.sass';
// import './img/main.bg.png';

class About extends Component {
	state = {
	}
	static contextType = PathContext;
	path = this.context;
	componentDidMount() {
		window.scrollTo(0, 0);

	}
	render() {

		return <div className={styles.wrap}>
			<div className={styles.bg1}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/about-bg-desktop.png`} />
					<img src={`${this.path}/img/about-bg.png`} alt="" />
				</picture>
			</div>
			<div className={styles.inner}>
				<div className="container">
					<h1 className={styles.title}>О проекте</h1>
					<div className={styles.text} data-aos="fade-up">
						<p>ВИЧ остается проблемой в России, потому что о нем стесняются говорить. Но мы хотим изменить ситуацию. </p>
						<p>В этом сторилендинге рассказываем о популярных заблуждениях и отвечаем на основные вопросы о ВИЧ.</p>
						<p>Наша цель — чтобы как можно больше людей сделали тест и узнали свой ВИЧ-статус.</p>
					</div>
					<div className={styles.body}>
						<div className={styles.command}>
							<div className={styles.command_title}   data-aos="fade-up">Команда проекта</div>
							<div   data-aos="fade-up">
							<div className={styles.people}>
								<span>Автор идеи: </span>
								<span>Николай Романов</span>
							</div>
							<div className={styles.people}>
								<span>Руководитель проекта: </span>
								<span>Анна Кузнецова</span>
							</div>
							<div className={styles.people}>
								<span>Дизайн: </span>
								<span>Леся Балашова</span>
							</div>
							<div className={styles.people}>
								<span>Разработка: </span>
								<span>Алексей Калиновский</span>
							</div>
							<div className={styles.people}>
								<span>Текст: </span>
								<span>Николай Романов, Анна Кузнецова, Леся Балашова </span>
							</div>
							<div className={styles.people}>
								<span>Редактор: </span>
								<span>Климент Лысенко</span>
							</div>
							<div className={styles.people}>
								<span>Художник-иллюстратор:  </span>
								<span>Елизавета Романова</span>
							</div>
							<div className={styles.people}>
								<span>Анимация:  </span>
								<span>Анар Сурутаева</span>
							</div>
							<div className={styles.people}>
								<span>Арт-директор: </span>
								<span>Артем Колеганов</span>
							</div>
							<div className={styles.people}>
								<span>Маркетинг: </span>
								<span>Федор Анисимов</span>
							</div>
						</div>
						</div>

						<div className={styles.sources}>

							<div className={styles.sources_title}   data-aos="fade-up">Источники</div>
							<div   data-aos="fade-up">
							<div className={styles.source}>
								<a className={styles.link} href="http://www.hivrussia.info/dannye-po-vich-infektsii-v-rossii/" rel="noreferrer" target="_blank">Федеральный научно-методический Центр по профилактике и борьбе со СПИДом</a>
							</div>
							<div className={styles.source}>
								<a className={styles.link} href="https://spid.center/ru/posts/4243/" rel="noreferrer" target="_blank">Фонд «СПИД.ЦЕНТР»</a>
							</div>
							<div className={styles.source}>
								<a className={styles.link} href="https://www.unaids.org/ru/frequently-asked-questions-about-hiv-and-aids" rel="noreferrer" target="_blank">ЮНЭЙДС</a>
							</div>
							<div className={styles.source}>
								<a className={styles.link} href="https://fedstat.ru/indicator/43700 " rel="noreferrer" target="_blank">Единая межведомственная информационно-статистическая система</a>
							</div>
							<div className={styles.source}>
								<a className={styles.link} href="https://www.thelancet.com/action/showPdf?pii=S0140-6736%2819%2930418-0" rel="noreferrer" target="_blank">Исследование PARTNER</a>
							</div>
						</div>
						</div>
					</div>

				</div>
			</div>
			{/* footer */}
			<div className={styles.footer}>

				<FooterAbout />
			</div>
		</div>;
	}
}

export default About;
