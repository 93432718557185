import React, { Component } from 'react';

import styles from './HeightSlider.module.sass';
// import './template.sass';

export default class HeightSlider extends Component {
  constructor(props) {
		super(props);
		this.state = {
      start: 0,
      end: 0,
      fixed: false
		};
    this.bodyStyle = {
    };
    this.progress = 0;
    // if(this.props.height !== undefined){
    //   this.sliderStyle.height = `${this.props.height * window.innerHeight}px`;
    // }else {
    //   this.sliderStyle.height = `${window.innerHeight}px`;
    // }
    this.myRef = React.createRef();
    this.bodyRef = React.createRef();
    this._isMounted = false;
	}

  
  componentDidMount(){
    this._isMounted = true;
    this.setHeight();
    this.fixedHandler = this.fixedHandler.bind(this);
    this.setHeight = this.setHeight.bind(this);
		window.addEventListener('scroll', this.fixedHandler);
		// window.addEventListener('resize', this.setHeight);
  }
	componentWillUnmount(){
    this._isMounted = false;
		window.removeEventListener('scroll', this.fixedHandler, false);
    // window.removeEventListener('resize', this.setHeight);
    this.setState = (state,callback)=>{
        return;
    };
	}
  setHeight(){
    if(this.props.height !== undefined){
      this.myRef.current.style.height = `${this.props.height * window.innerHeight}px`;
      // this.bodyRef.current.style.height = `${window.innerHeight}px`;
    }else {
      this.myRef.current.style.height = `${window.innerHeight}px`;
    }
    const thisHeightSlider = this.myRef.current;
    // console.log(thisHeightSlider.getBoundingClientRect().top);
    this.setState({
      start: thisHeightSlider.getBoundingClientRect().top  + window.scrollY,
      end: thisHeightSlider.getBoundingClientRect().bottom - window.innerHeight + window.scrollY
    });
  }
  fixedHandler(){
    // console.log(this.myRef.current.offsetHeight - this.bodyRef.current.offsetHeight);
    // console.log(this.myRef.current.getBoundingClientRect().bottom - this.bodyRef.current.getBoundingClientRect().bottom );
    if(this.myRef.current.getBoundingClientRect().top < 0 && this.myRef.current.getBoundingClientRect().bottom - this.bodyRef.current.getBoundingClientRect().bottom > 0){
      // this.bodyStyle.position = 'fixed';
      // this.bodyStyle.top = '0';
      // this.bodyStyle.bottom = 'unset';
      // console.log(this.myRef.current.offsetHeight - this.bodyRef.current.offsetHeight);
      this.progress = Math.floor(100-((this.myRef.current.getBoundingClientRect().bottom - this.bodyRef.current.getBoundingClientRect().bottom) / (this.myRef.current.offsetHeight - this.bodyRef.current.offsetHeight))*100);
     
      if(this.props.detailProgress){
        this.progress = 100-((this.myRef.current.getBoundingClientRect().bottom - this.bodyRef.current.getBoundingClientRect().bottom) / (this.myRef.current.offsetHeight - this.bodyRef.current.offsetHeight))*100;
      } else {
        this.progress = Math.floor(100-((this.myRef.current.getBoundingClientRect().bottom - this.bodyRef.current.getBoundingClientRect().bottom) / (this.myRef.current.offsetHeight - this.bodyRef.current.offsetHeight))*100);
      }
    } else if(this.myRef.current.getBoundingClientRect().top >= 0){
      this.progress = 0;
      // this.bodyStyle.top = '0';
      // this.bodyStyle.bottom = 'unset';
    }else {
      this.progress = 100;
      // this.bodyStyle.bottom = '0';
      // this.bodyStyle.top = 'unset';
    }
    this.setState({});
  }

	render() {
    
    // this.fixedHandler();
    
    
		return (
        <>
        <div ref={this.myRef} className={styles.slider}>
            <div ref={this.bodyRef} className={styles.body} style={this.bodyStyle}>
              {React.cloneElement(this.props.children, { progress: this.progress })}
            </div>
          </div>
        </>
      );
  }
}
    
