import React, { Component } from 'react';
import PathContext from "../../context/path";
import Cards from '../../components/Cards';
import FooterCover from '../../components/FooterCover';
import Footer from '../../components/Footer';
// import ScrollTop from '../../components/ScrollTop';
import Socials from '../../components/Socials';
import ScrollDown from '../../components/ScrollDown';
import styles from './Risk.module.sass';

class Risk extends Component {
	state = {
		initGif: false,
		start: 0,
		date: 2000
	}
	refGif = React.createRef();
	refWrap = React.createRef();
	interval = null;
	static contextType = PathContext;
	path = this.context;
	componentDidMount() {
		window.scrollTo(0, 0);

		window.addEventListener('scroll', this.checkGif.bind(this));

		const refGif = this.refGif.current;
		
		this.setHeight = this.setHeight.bind(this);
		this.setHeight();
		window.addEventListener('resize', this.setHeight);
		this.setState({
			start: refGif.getBoundingClientRect().top + window.scrollY - window.innerHeight / 2,
		});
	}
	
	setHeight() {
		let vh = window.innerHeight * 0.01;
		this.refWrap.current.style.setProperty('--vh', `${vh}px`);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.setHeight, false);
		window.removeEventListener('scroll', this.checkGif.bind(this));
		this.setState = (state, callback) => {
			return;
		};
	}
	componentDidUpdate() {
		if (this.state.initGif) {
			clearInterval(this.interval);
			this.interval = setInterval(() => {
				if (this.state.date < 2021) {
					this.setState({
						date: this.state.date + 1
					});
				} else {
					clearInterval(this.interval);
				}
			}, 95);
		} else {
			clearInterval(this.interval);
			this.interval = setInterval(() => {
				if (this.state.date > 2000) {
					this.setState({
						date: this.state.date - 1
					});
				} else {
					clearInterval(this.interval);
				}
			}, 95);
		}
	}
	checkGif() {
		if (window.scrollY > this.state.start) {
			if (!this.state.initGif) {
				this.setState({
					initGif: true,
				});
			}


		} else {
			if (this.state.initGif) {
				this.setState({
					initGif: false,
				});
			}
		}
	}

	render() {
		let gifClass = `${styles.gif}`;
		if (this.state.initGif) {
			gifClass = `${styles.gif} ${styles.gif_active}`;
		} else {
			gifClass = `${styles.gif}`;
		}
		
		return <div  ref={this.refWrap} className={styles.wrap}>

			{/* BG */}
			<div className={styles.bg1}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/risk-bg-desktop.png`} />
					<img src={`${this.path}/img/risk-bg.png`} alt=""/>
				</picture>
			</div>
			<div className={styles.bg2}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/risk-bg2-desktop.png`} />
					<img src={`${this.path}/img/risk-bg2.png`} alt=""/>
				</picture>
			</div>
			{/* COVER */}
			<div className={styles.cover}>
				<div className="container">
					<h1 className={styles.title}>
					Я могу быть в группе риска?
					</h1>
					<div className={styles.subtitle} data-aos="fade-up">
					Пути передачи ВИЧ и как себя обезопасить
					</div>
					<div className={styles.socials}>
					<Socials suffix={'blue'}/>
					</div>
					<div className={styles.img}>
						<img src={`${this.path}/img/gifs/icon1.gif`} alt="" loading="lazy"/>
					</div>
				</div>
				<ScrollDown />
			</div>

			<div className={styles.info}>
				<div className="container">
					<div  data-aos="fade-up">
					<h2>ВИЧ-инфекция поражает всех, независимо от положения в обществе, ориентации и рода занятий</h2>
					<div className={styles.text}>
						<p>За последние два десятилетия ВИЧ-инфекция перестала быть проблемой маргиналов.</p>
					</div>
					</div>

					<div ref={this.refGif} className={gifClass}>
						<div className={styles.gif_title}><b>Группы распространения ВИЧ</b></div>
						<div className={styles.gif_number}>{this.state.date}</div>
						<div className={styles.gif_bar}>
							<span className={`${styles.gif_subbar} ${styles.gif_subbar_blue}`}></span>
							
							<span className={`${styles.gif_subbar} ${styles.gif_subbar_red}`}></span>
						</div>
					</div>
					<div className={styles.bar_text}  data-aos="fade-up">
						<span>Наркопотребители, гомосексуалы и секс-работники </span> — основная группа риска 90-ых, ставшая стереотипом. Но с 2000 года ситуация изменилась. Сегодня ВИЧ в 65% случаев передается через <span>гетеросексуальный</span> половой контакт.
					</div>

					<div className={styles.lead}  data-aos="fade-up">С ВИЧ живут разные люди независимо от увлечений и интересов, пристрастий в сексе или количества денег на карте</div>
					<div className={styles.text}  data-aos="fade-up">
						Сегодня большое количество впервые выявленных ВИЧ-статусов приходится на представителей среднего возраста.
					</div>
					<div className={styles.stats_wrap}>
					<div className={styles.stats}>
						<svg className={styles.svg} width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="https://www.w3.org/2000/svg">
							<circle cx="95" cy="95" r="80" strokeWidth="15" stroke="#E2E2E2"></circle>
							<circle className={styles.svg_circle} cx="95" cy="95" r="80" strokeWidth="15" stroke="#2F80ED"></circle>
						</svg>
						{/* <div className={styles.stats_img}>
						<picture>
							<source media="(min-width: 768px)" srcSet={`${this.path}/img/stats-img-desktop.png`} />
							<img src='' alt="" loading="lazy"/>
						</picture>
						</div> */}
					</div>
					<div className={styles.fact}  data-aos="fade-up">
						<div className={styles.fact_number}>30-49 лет</div>
						<div className={styles.fact_text}>возрастной интервал, в который попадают 71% людей с впервые выявленным положительным ВИЧ-статусом</div>

					</div>
					</div>
					<div className={styles.quote}  data-aos="fade-up">
						<div className={styles.quote_icon}>
							<img src={`${this.path}/img/quote.svg`} alt="" />
						</div>
						<div className={styles.quote_text}>ВИЧ не выбирает людей по образу жизни, возрасту или каким-то еще характеристикам: эпидемия вышла из ключевых групп и большие риски есть у тех, кто считают, что ВИЧ их не касается, а потому не тестируются и не думают о профилактике. Среди взрослых и даже пожилых пациентов с ВИЧ есть как те, кто получили вирус давно и по счастливой случайности узнали о нем, так и те, кто инфицировались во время недавнего контакта".</div>
						<div className={styles.quote_name}>Антон Еремин,  </div>
						<div className={styles.quote_status}>врач-инфекционист</div>
					</div>
				</div>
			</div>

			<div className={styles.info_2}>
				<div className="container">
					<div   data-aos="fade-up">
					<h2>Основной путь инфекции: секс без защиты</h2>
					<div className={styles.text}>
						<p>Чтобы инфицировать человека, ВИЧ должен в достаточном количестве попасть на слизистую. А достаточное количество вируса содержится в крови, сперме, предэякуляте, вагинальной смазке и грудном молоке.</p>
					</div>
					</div>
					<div className={styles.fact_2}>
						<div className={styles.fact_img}>
							<picture>
								<source media="(min-width: 768px)" srcSet={`${this.path}/img/risk-img-1-desktop.png`} />
							<img src={`${this.path}/img/risk-img-1.png`} alt="" />
							</picture>
						</div>
						<div  data-aos="fade-up">
						<div className={styles.fact_number}>7 из 10 </div>
						<div className={styles.fact_text}>человек получают ВИЧ через незащищенный секс</div>
						</div>

					</div>
					<div className={`${styles.text_2}`}  data-aos="fade-up">
						<p>ВИЧ не передаётся по воздуху, через поцелуи, прикосновения, посуду, душ, туалет и другие бытовые вещи. </p>
						<p>Смело целуйтесь в баре, но если дело дойдёт до секса — предохраняйтесь! </p>
					</div>
					<div className={styles.illustration}>
						<picture>
							<source media="(min-width: 768px)" srcSet={`${this.path}/img/gifs/gruppa-riska-desktop.gif`} />
							<img src={`${this.path}/img/gifs/gruppa-riska.gif`} alt="" loading="lazy"/>
						</picture>
					</div>
				</div>
			</div>


			<div className={styles.info_3}>
				<div className="container">
					<div  data-aos="fade-up">
					<h2>Знать ВИЧ-статус, чтобы быть в безопасности</h2>
					<div className={styles.text_3}>
						<p>Даже самые информированные люди время от времени занимаются сексом без защиты: не нашлось под рукой, попросил партнер, презерватив просто порвался или по другой причине.</p>
						<p>Тестирование нужно проходить всем людям как минимум раз в год. Это поможет сформировать привычку, избавиться от страха перед тестом и подстраховаться на случай, если риск был, а его не заметили.</p>
						<p>Если человек хотя бы раз занимался сексом без презерватива, стоит пройти тестирование.</p>
					</div>
					</div>
					<div className={`${styles.quote} ${styles.quote_2}`}>
						<div className={styles.quote_icon}>
							<img src={`${this.path}/img/quote.svg`} alt="" />
						</div>
						<div className={styles.quote_text}   data-aos="fade-up">Рекомендуем сдавать тест на ВИЧ независимо от рисков каждый год. Людям с высокими рисками — каждые полгода, а людям, употребляющим инъекционные наркотики или практикующим секс под веществами — каждые три месяца. Если произошел риск, то тест нужно сделать через три месяца после него, чтобы получить достоверный результат. Если до риска вы не тестировались больше года, то лучше сделать два теста: сразу и через три месяца".</div>
						<div className={styles.quote_img}  data-aos="fade-up">
						<picture >
							<source media="(min-width: 768px)" srcSet={`${this.path}/img/risk-img-3-desktop.svg`} />
							<img src={`${this.path}/img/risk-img-3.svg`} alt="" loading="lazy"/>
						</picture>
							
						</div>
						<div  data-aos="fade-up">
						<div className={styles.quote_name}>Майя Демидова, </div>
						<div className={styles.quote_status}>координатор тестирования</div>
					</div>
					</div>
				</div>
			</div>
			<div className={styles.faq}>
				<div className="container">

					<div className={styles.footer_cover}>
						<FooterCover />
					</div>
					<div className={styles.cards}>
						<Cards />
					</div>
				</div>
			</div>
			{/* <ScrollTop color={'blue'} /> */}
			<div className={styles.footer}>
				<Footer />
			</div>
		</div>;
	}
}

export default Risk;
