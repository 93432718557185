import React, { Component } from "react";
import styles from "./ProgressMain.module.sass";
import PathContext from "../../context/path";
// import Circles from '../Circles/Circles';
import { ReactComponent as SVG1 } from './svg1.svg';
import { ReactComponent as SVG2 } from './svg2.svg';
class ProgressMain extends Component {
	state = {
		bodyOffset: false,
		active: false
	};

	refWrap = React.createRef();
	
	static contextType = PathContext;
	path = this.context;
	componentDidMount() {
		// window.scrollTo(0, 0);
		if(window.innerWidth > 768){
			this.setState({
				bodyOffset: this.refWrap.current.getBoundingClientRect().bottom + window.scrollY - window.innerHeight*0.9
			});
		}else{
			this.setState({
				bodyOffset: this.refWrap.current.getBoundingClientRect().bottom + window.scrollY - window.innerHeight*0.9
			});
		}
		this.handleScroll = this.handleScroll.bind(this);
		window.addEventListener("scroll", this.handleScroll);
	}
	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll, false);
		this.setState = (state, callback) => {
			return;
		};
	}
	handleScroll() {
		
		if(this.state.bodyOffset < window.scrollY){
			if(this.state.active === false){
				this.setState({
					active: true
				});
			}
			
		}else{
			if(this.state.active === true){
				this.setState({
					active: false
				});
			}
		}
		// console.log(this.refWrap.current.getBoundingClientRect().top - (this.refWrap.current.offsetHeight / 4));
	}
	render() {
		let wrapClass = `${styles.wrap}`;
		if (this.state.active === true) {
			wrapClass = `${styles.wrap} ${styles.wrap_active}`
		} else {
			wrapClass = `${styles.wrap}`;
		}

		return (
			<>
				<div ref={this.refWrap} className={wrapClass} >
					<div className={styles.items}>
						<div className={`${styles.item} ${styles.item_1} js-fact-1`}>
							<div className={styles.fact}>
								<div className={styles.fact_body} data-aos="fade-up">
									<div className={styles.fact_number}>1 122 879</div>
									<div className={styles.fact_text}> человек с подтвержденным положительным ВИЧ-статусом живут сегодня  в России
									</div>
								</div>
								<div className={styles.fact_svg}>

									<SVG1 />
								</div>
							</div>
						</div>
						<div className={`${styles.item} ${styles.item_2} js-fact-2`}>
							<div className={styles.fact}>

								<div className={styles.fact_svg}>

									<SVG2 />
								</div>
								<div className={styles.fact_body}>

									<div className={styles.fact_number}>72 023</div>
									<div className={styles.fact_text}>
										<p>из них узнали о своем положительном  ВИЧ-статусе  в 2020 году. </p>
										<p>Это сравнимо с населением Ялты.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default ProgressMain;
