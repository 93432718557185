
import React, { Component } from 'react';
import PathContext from "../../context/path";
import styles from './FooterCover.module.sass';

class FooterCover extends Component {
	static contextType = PathContext;
	path = this.context;

	render() {
		
	let textSpan = 'Результат известен через 15 минут';
	let textSpan2 = 'Точность теста: 99,9%';
	if(window.innerWidth > 767){
		textSpan = 'Результат через 15 минут';
		textSpan2 = 'Точность теста —  99,9%';
	}
		return (
			<>
				<div className={styles.wrap}>
					<div className={styles.body}>
						<div className={styles.img}>
							<picture>
								<source media="(min-width: 768px)" srcSet={`${this.path}/img/footer-cover-desktop.png`} />
								<img src={`${this.path}/img/footer-cover.png`} alt="" loading="lazy"/>
							</picture>
						</div>
						<h3 className={styles.title}  data-aos="fade-up">Тесты бывают разные, самый удобный
						<span className={styles.label}>экспресс-тест</span></h3>
						
						<div className={styles.props}  data-aos="fade-up">
							<div className={styles.prop}>
								<span>Быстро</span>
								<span>{textSpan}</span>
							</div>
							<div className={styles.prop}>
								<span>Анонимно</span>
								<span>Можно купить в аптеке, онлайн-магазине или пройти бесплатно в НКО</span>
							</div>
							<div className={styles.prop}>
								<span>Точно</span>
								<span>{textSpan2}</span>
							</div>

						</div>

					</div>
					<div className={styles.footer}>
						<div className={styles.text}>Сдайте тест сами <br /> и позаботьтесь о близких.</div>
						<div className={styles.links}>
						<a href='https://spid.center/ru/test/' className={styles.link}>
							{/* <span className={styles.preicon}><img src={`${this.path}/img/icon-link.svg`} alt="" /></span>  */}
							<span> В Москве, Санкт-Петербурге, Нижнем Новгороде, скоро в Краснодаре <span className={styles.posticon}> <img src={`${this.path}/img/icon-post-link.svg`} alt="" /></span> </span></a>
						<a href='https://human.org.ru/resource-center/hivngos/' className={styles.link}>
							{/* <span className={styles.preicon}><img src={`${this.path}/img/icon-link.svg`} alt="" /></span>  */}
							<span>В других городах России <span className={styles.posticon}> <img src={`${this.path}/img/icon-post-link.svg`} alt="" /></span> </span></a>
					</div>
					</div>
				</div>

			</>
		);
	}
}

export default FooterCover;
