
import React, { Component } from 'react';
import styles from './ProgressFeel.module.sass';
import PathContext from "../../context/path";
import Circles from '../Circles/Circles';
class ProgressFeel extends Component {


	state = {
		opened: false,
		stages: 14,

		step: 7.14
	};
	stage = 0;
	refTitle = React.createRef();
	refDate = React.createRef();
	refText = React.createRef();

	data = [
		{
			"stage": 0,
			"title": "",
			"date": "",
			"img": "",
			"text": ""
		},
		{
			"stage": 1,
			"title": "Острая ВИЧ-инфекция",
			"date": "от 2-х недель до полугода",
			"img": "/img/gifs/walk/walk1.gif",
			"text": "После инфицирования вы можете испытывать легкие симптомы, как у простуды. Но, скорее всего, ничего не почувствуете."
		},
		{
			"stage": 2,
			"title": "Бессимптомная стадия ",
			"date": "1 год",
			"img": "/img/gifs/walk/walk1.gif",
			"text": "Это самая долгая стадия вируса. Вирус размножается, уничтожая клетки иммунитета. Вы ничего не замечаете."
		},
		{
			"stage": 3,
			"title": "Бессимптомная стадия ",
			"date": "2 года",
			"img": "/img/gifs/walk/walk1.gif",
			"text": "Это самая долгая стадия вируса. Вирус размножается, уничтожая клетки иммунитета. Вы ничего не замечаете."
		},
		{
			"stage": 4,
			"title": "Бессимптомная стадия ",
			"date": "3 года",
			"img": "/img/gifs/walk/walk1.gif",
			"text": "Это самая долгая стадия вируса. Вирус размножается, уничтожая клетки иммунитета. Вы ничего не замечаете."
		},
		{
			"stage": 5,
			"title": "Бессимптомная стадия ",
			"date": "4 года",
			"img": "/img/gifs/walk/walk1.gif",
			"text": "Это самая долгая стадия вируса. Вирус размножается, уничтожая клетки иммунитета. Вы ничего не замечаете."
		},
		{
			"stage": 6,
			"title": "Бессимптомная стадия ",
			"date": "5 лет",
			"img": "/img/gifs/walk/walk2.gif",
			"text": "За это время вы могли совершить кругосветное путешествие, вырастить небольшое дерево, сменить род деятельности. И ни о чем не подозревать."
		},
		{
			"stage": 7,
			"title": "Бессимптомная стадия ",
			"date": "6 лет",
			"img": "/img/gifs/walk/walk3.gif",
			"text": "У некоторых людей эта стадия может длиться 20 и более лет."
		},
		{
			"stage": 8,
			"title": "Бессимптомная стадия ",
			"date": "7 лет",
			"img": "/img/gifs/walk/walk3.gif",
			"text": "У некоторых людей эта стадия может длиться 20 и более лет."
		},
		{
			"stage": 9,
			"title": "Бессимптомная стадия ",
			"date": "8 лет",
			"img": "/img/gifs/walk/walk3.gif",
			"text": "У некоторых людей эта стадия может длиться 20 и более лет."
		},
		{
			"stage": 10,
			"title": "Терминальная стадия, СПИД",
			"date": "9 лет",
			"img": "/img/gifs/walk/walk4.gif",
			"text": "Только спустя 8–10 лет вирус дает о себе знать. Иммунные клетки достигают критического минимума. ВИЧ переходит в стадию СПИД — синдрома приобретенного иммунодефицита."
		},
		{
			"stage": 11,
			"title": "Терминальная стадия, СПИД",
			"date": "10–12 лет",
			"img": "/img/gifs/walk/walk4_5.gif",
			"text": "Приблизительно за три года СПИДа иммунная система полностью перестает выполнять свои защитные функции."
		},
		{
			"stage": 12,
			"title": "Терминальная стадия, СПИД",
			"date": "10–12 лет",
			"img": "/img/gifs/walk/walk5.gif",
			"text": "Организм сдается"
		},
		{
			"stage": 13,
			"title": "Терминальная стадия, СПИД",
			"date": "10–12 лет",
			"img": "/img/gifs/walk/walk5.gif",
			"text": "Организм сдается"
		}
	];
	title = '';
	date = '';
	text = '';
	img = '/img/gifs/walk/walk1.gif';
	stageBody = null;
	static contextType = PathContext;
	path = this.context;
	componentDidMount() {
		this.stage = Math.round(this.props.progress / this.state.step);
		this.setStage = this.setStage.bind(this);
		this.setStage();
		this.data.forEach((item) => {
			const img = new Image();
			img.src = `${this.path}${item.img}`;
		});
		window.addEventListener('scroll', this.setStage);
		this.setState({});

	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.setStage.bind(this), false);
	}

	checkData(stage) {
		if (this.data[this.stage].title !== this.data[stage].title) {
			// this.refTitle.current.style.opacity = 0;
			this.title = this.data[stage].title;
			this.setState({});
		}
		if (this.data[this.stage].date !== this.data[stage].date) {
			this.date = this.data[stage].date;
			this.setState({});
		}
		if (this.data[this.stage].text !== this.data[stage].text) {
			if (this.data[stage].stage === 6) {

				this.text = <span>{this.data[stage].text}</span>;
			} else {
				this.text = this.data[stage].text;
			}
			this.setState({});
		}
		if (this.data[this.stage].img !== this.data[stage].img) {
			this.img = this.data[stage].img;
			this.setState({});
		}
	}
	setStage() {
		let stage = Math.round(this.props.progress / this.state.step);

		if (stage <= (this.state.stages - 1)) {

			this.checkData(stage);
			this.stage = stage;
		}

		if (stage === 0) {
			this.stageBody = <div className={styles.stage}>
				<div className={styles.stat}>
					<div className={`${styles.stat_path} ${styles.stat_path_left}`}>
						<div className={styles.stat_bar}></div>
						<div className={styles.stat_number}>6–8 лет</div>
						<div className={styles.stat_text}>ВИЧ прогрессирует без симптомов</div>
					</div>
					<div className={`${styles.stat_path} ${styles.stat_path_right}`}>
						<div className={styles.stat_bar}></div>
						<div className={styles.stat_number}>2-3 года</div>
						<div className={styles.stat_text}>Первые ощутимые симптомы</div>
					</div>
				</div>
				<div className={styles.text_blue}  data-aos="fade-up">Если вы инфицированы, большую часть времени, скорее всего, вы не будете ощущать присутствие вируса</div>
				<div className={styles.scroll}>
					<span>Скрольте, чтобы запустить таймлайн</span>
					<img src={`${this.path}/img/scroll-arrow.svg`} alt="" />
				</div>

			</div>;
		} else {
			let legend = null;
			let scroll = <img src={`${this.path}/img/scroll-arrow.svg`} alt="" />;
			if (stage === 1) {
				legend = <div className={styles.legend}>
					<span>Клетки вируса</span>
					<span>Клетки иммунитета</span>
				</div>
			}
			if (stage === 14) {
				scroll = null;
			} else {
				scroll = <img src={`${this.path}/img/scroll-arrow.svg`} alt="" />;
			}
			this.stageBody = <div className={`${styles.stage}`}>
				<div ref={this.refTitle} className={styles.stage_title}>{this.title}</div>
				<div className={styles.bar}>
					<div style={{ width: `${this.props.progress}%` }}>
						<span></span>
						<span></span>
					</div>
				</div>
				<div ref={this.refDate} className={styles.date}>{this.date}</div>
				<div className={styles.body}>
					{legend}
					<div className={styles.img}>
						<img src={`${this.path}${this.img}`} alt="" />
					</div>
					<div className={styles.circles}>
						<Circles perRow={12} allCircles={96} />
					</div>
				</div>
				<div ref={this.refText} className={styles.stage_text}>{this.text}</div>
				<div className={styles.scroll}>

					{scroll}
				</div>
			</div>;
			let circles = document.querySelectorAll('.circle');
			let step = 96 / 99;
			let numbers = Math.round(this.props.progress * step)
			let activeColor = '#2F80ED';

			if (this.props.progress < 66) {
				activeColor = '#2F80ED';
			} else {
				activeColor = '#FD4B2C';
			}
			circles.forEach((circle, number) => {
				if (number < numbers) {
					circle.style.setProperty('--color', `${activeColor}`);
				} else {
					circle.style.setProperty('--color', '#E0E0E0');
				}
			});
		}
	}
	render() {
		let titleClass = `${styles.title}`;
		let textClass = `${styles.text}`;
		let stageClass = `${styles.stage_body}`;
		if (this.props.progress > 3 && window.innerWidth > 767) {
			// titleClass = `${styles.title} ${styles.title_hide}`;
			textClass = `${styles.text} ${styles.text_hide}`;
			stageClass = `${styles.stage_body} ${styles.stage_body_active}`;
		} else {
			// titleClass = `${styles.title}`;
			textClass = `${styles.text}`;
			stageClass = `${styles.stage_body}`;
		}
		return (
			<div className={styles.inner}>
				<div className={styles.bg1}>
					<picture>
						<source media="(min-width: 768px)" srcSet={`${this.path}/img/feel-progress-desktop.png`} />
						<img src={`${this.path}/img/feel-progress-bg.png`} alt="" />
					</picture>
				</div>

				<div className="container"  data-aos="fade-up">
					<h2 className={titleClass}>
						ВИЧ прогрессирует медленно и незаметно
					</h2>
					<div className={textClass}>
						Опасность ВИЧ-инфекции в том, что она может протекать без симптомов в течение многих лет. Все это время человек активно передает вирус и не подозревает о том, что болеет.
					</div>
					<div className={stageClass}>
						{this.stageBody}
					</div>
				</div>

			</div>
		);
	}
}

export default ProgressFeel;
