
import React, { Component } from 'react';
import {
	NavLink,
	Link,
	withRouter 
} from "react-router-dom";
import Socials from '../../components/Socials';
import styles from './Header.module.sass';
import PathContext from "../../context/path";
class Header extends Component {


	state = {
		init: false,
		opened: false
	};
	static contextType = PathContext;
	path = this.context;
	data = [
		{
			"path": "/",
			"title": "Вернуться на главную"
		},
		{
			"path": "/feel",
			"title": "Я чувствую себя хорошо. Я могу быть болен?",
			"text": "Как развивается вирус: симптомы и возможные признаки",
			"img": "/img/card-feel.png"
		},
		{
			"path": "/risk",
			"title": "Я могу быть в группе риска?",
			"text": "Пути передачи ВИЧ и как себя обезопасить",
			"img": "/img/card-risk.png"
		},
		{
			"path": "/therapy",
			"title": "ВИЧ — это всегда смертельно опасно?",
			"text": "Как работает терапия и почему она нужна",
			"img": "/img/card-therapy.png"
		},
		{
			"path": "/talk",
			"title": "Как говорить о ВИЧ и почему это необходимо?",
			"text": "Как убедить партнера пройти тестирование",
			"img": "/img/card-talk.png"
		},
		{
			"path": "/test",
			"title": "Что вы знаете о ВИЧ-инфекции? Тест",
			"text": "Проверьте уровень своих знаний.",
			"img": "/img/card-test.png"
		},
		{
			"path": "/about",
			"title": "О проекте",
		}
	];
	componentDidMount() {
		// const {pathname} = this.props.location;
  		
		this.cards = this.data.map((item, index) => {


			if (window.innerWidth > 767) {
				// let linkClass = item.path === path? `${styles.link} ${styles.link_current}` : `${styles.link}`;
				if (item.text === undefined) {
					return <NavLink exact to={item.path} key={index} onClick={this.toggleMenu.bind(this)} className={isActive => `${styles.link}` + (isActive ? ` ${styles.link_current}` : "")} activeClassName="">{item.title}</NavLink >;
				} else {

					return <NavLink exact to={item.path} key={index} onClick={this.toggleMenu.bind(this)} className={isActive => `${styles.link} ${styles.link_card}` + (isActive ? ` ${styles.link_current}` : "")} activeClassName="">
						<div className={styles.body}>
							<div className={styles.title}>{item.title}</div>
							<div className={styles.text}>{item.text}</div>
						</div>
						<div className={styles.img}>
							<img src={`${this.path}${item.img}`} alt="" />
						</div></NavLink >;
				}
			} else {
				return <Link to={item.path} key={index} onClick={this.toggleMenu.bind(this)} className={styles.link}>{item.title}</Link >;
			}

		});
		this.setState({});
	}

	toggleMenu() {
		this.setState({
			opened: !this.state.opened
		});
	}
	render() {
		let menuClass = `${styles.menu}`;
		if (this.state.opened) {
			menuClass = `${styles.menu} ${styles.menu_open}`;
		} else {
			menuClass = `${styles.menu}`;
		}
		const {pathname} = this.props.location;
		
		return (
			<>
				<header className={styles.header}>
					<div className="container">
						<div className={styles.inner}>
							<div className={styles.burger} onClick={this.toggleMenu.bind(this)}>
								<img src={`${this.path}/img/burger-icon.svg`} alt="" />
							</div>
						</div>
					</div>
					<div className={menuClass}>
						<div className={styles.close} onClick={this.toggleMenu.bind(this)}>
							<img src={`${this.path}/img/close-icon.svg`} alt="" />
						</div>
						<div className={styles.list}>
							<div className={styles.langs}>
								<a href={`https://hiv.infografika.agency${pathname}`} className={`${styles.lang} ${styles.lang_active}`}>RU</a >
								<a href={`https://hiv.infografika.agency/en${pathname}`} className={styles.lang}>EN</a >
							</div>
							{this.cards}
							<div className={styles.socials}>
								<Socials />
							</div>
						</div>

					</div>

				</header>

			</>
		);
	}
}

export default withRouter(Header);
