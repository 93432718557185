
import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
	Link,
	withRouter,
} from 'react-router-dom';
import PathContext from "../../context/path";
import styles from './Cards.module.sass';

class Cards extends Component {
	
	constructor(props) {
		super(props);
		this.state = { 
			init: false
		 }

		 
	}
	data=[
		{
			"path": "/feel",
			"title": "Я чувствую себя хорошо. Я могу быть болен?",
			"text": "Как развивается вирус: симптомы и возможные признаки",
			"img": "/img/card-feel.png"
		},
		{
			"path": "/risk",
			"title": "Я могу быть в группе риска?",
			"text": "Пути передачи ВИЧ и как себя обезопасить",
			"img": "/img/card-risk.png"
		},
		{
			"path": "/therapy",
			"title": "ВИЧ — это всегда смертельно опасно?",
			"text": "Как работает терапия и почему она нужна",
			"img": "/img/card-therapy.png",
			"imgDesc": "/img/card-therapy-desc.png"
		},
		{
			"path": "/talk",
			"title": "Как говорить о ВИЧ и почему это необходимо?",
			"text": "Как убедить партнера пройти тестирование",
			"img": "/img/card-talk.png"
		},
		{
			"path": "/test",
			"title": "Тест.\nЧто вы знаете о ВИЧ-инфекции?",
			"text": "Проверьте уровень своих знаний.",
			"img": "/img/card-test.png"
		}
	];
	cards = [];
	wrap = null;
	initSlide = 0;
	static contextType = PathContext;
	path = this.context;
	componentDidMount(){
		const { path } = this.props.match;
		this.path = this.context;
		let cendered = true;
		if(window.innerWidth > 767){
			cendered = false;
		}
		this.cards = this.data.map((item, index)=>{
			if(item.path === path && path !== '/'){
				this.initSlide = index;
				return null;
			}else if(path !== '/'){
				
				return <SwiperSlide key={index}>
					<Link to={item.path} key={index} className={`${styles.card} ${styles.card_small} ${styles[`card_${item.path.slice(1)}`]}`}>
					<div className={styles.body}>
						<div className={styles.title}>{item.title}</div>
					</div>
					<div className={styles.img}>
						<img src={`${this.path}${item.img}`} alt="" />
					</div>
				</Link>
				</SwiperSlide>;
			}else {
				let bg = null;
				let img = <img src={`${this.path}${item.img}`} alt="" />;
				if(item.imgDesc !== undefined){
					img = <picture>
						<source media="(min-width: 768px)" srcSet={`${this.path}${item.imgDesc}`} />
						<img src={`${this.path}${item.img}`} alt="" />
					</picture>
				}
				
				if(item.path === '/test' && window.innerWidth > 767){
					bg = <div className={styles.bg}>
						<img src={`${this.path}/img/card-test-bg.png`} alt="" />
					</div>
				}
				return <Link to={item.path} key={index} className={`${styles.card} ${styles[`card_${item.path.slice(1)}`]} `}>
					<div className={styles.body}>
						<div className={styles.title}>{item.title}</div>
						<div className={styles.text}>{item.text}</div>
					</div>
					<>
					{bg}
					</>
					<div className={styles.img}>
						{img}
					</div>
				</Link>;
			}
		});
		if(path !== '/'){
			this.wrap = <Swiper  spaceBetween={10} slidesPerView={'auto'} initialSlide={this.initSlide} centeredSlides={cendered} className={styles.wrap}>{this.cards}</Swiper>
		} else {
			this.wrap = this.cards;
		}
		this.setState({
			init: true
		});
	}
	render() {
		
		

		return (
			<>
				{this.wrap}
			</>
		);
	}
}

export default withRouter(Cards);
