
import React, { Component } from 'react';


import styles from './Circles.module.sass';

class Circles extends Component {
	
	constructor(props) {
		super(props);
		this.state = { 
			init: false
		 }
	}
	rows = null;
	componentDidMount(){

		const {perRow, allCircles, dirX, dirY} = this.props;
		const allRows = Math.floor(allCircles/perRow);
		let row = [];
		let circles = [];
		let classRows = `${styles.rows}`;
		if(dirX === 'bottom'){
			classRows += ` ${styles.rows_bottom}`
		}
		if(dirY === 'right'){
			classRows += ` ${styles.rows_right}`
		}
		for(let j = 0; j < perRow; j++){
			
			circles[j] = <div className={`${styles.circle} circle`} key={j}></div>;
		}
		for(let i = 0; i < allRows; i++){

			row[i] = <div className={styles.row} key={i}>{circles}</div>;
		}
		
		if(allCircles % perRow !== 0){
			
			let countLastCircles = allCircles - (allRows*perRow);
			let lastCircles = [];
			for(let l = 0; l < countLastCircles; l++){
				lastCircles[l] = <div className={`${styles.circle} circle`} key={l}></div>;
			}
			let lastRow = <div className={styles.row} key={allRows+1}>{lastCircles}</div>;
			row.push(lastRow);
		} 

		this.rows = <div className={`${classRows}`}>{row}</div>;
		this.setState({
			init: true
		});
	}
	render() {
		
	

		return (
			<>
				{this.rows}
			</>
		);
	}
}

export default Circles;
