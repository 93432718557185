import React, { Component } from 'react';

import Cards from '../../components/Cards';
import FooterMain from '../../components/FooterMain';
import ProgressMain from '../../components/ProgressMain';
import ScrollDown from '../../components/ScrollDown';
import PathContext from "../../context/path";
import styles from './Main.module.sass';
// import './img/main.bg.png';

class Main extends Component {
	state = {
		bodyOffset: false
	}
	bodyOffset = 0;
	refBody = React.createRef();
	refWrap = React.createRef();
	static contextType = PathContext;
	path = this.context;
	componentDidMount(){
		document.querySelector('body').style.overflow = '';
		window.scrollTo(0, 0);
		this.setHeight = this.setHeight.bind(this);
		this.setHeight();
		window.addEventListener('resize', this.setHeight);

	}
	componentWillUnmount(){
		window.removeEventListener('resize', this.setHeight, false);
	}
	setHeight() {
		let vh = window.innerHeight * 0.01;
		this.refWrap.current.style.setProperty('--vh', `${vh}px`);
	}
	render() {
		
		
		return <div ref={this.refWrap} className={styles.wrap}>
			
			{/* BG */}
			<div className={styles.bg1}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/main-bg-desktop.png`} />
					<img src={`${this.path}/img/main-bg.png`} alt="" />
				</picture>
			</div>
			
			{/* COVER */}
			<div className={styles.cover}>
				<div className={styles.container}>
					<h1 className={styles.title}>
						<span>ВИЧ.</span> Ответы, которые вы (не) знали
					</h1>
					<div className={styles.img_wrap}>
						<div className={`${styles.img} ${styles.img_1}`}>
							<picture>
								<img src={`${this.path}/img/gifs/icon1.gif`}alt="" />
							</picture>
						</div>
						<div className={`${styles.img} ${styles.img_2}`}>
							<picture>
								<img src={`${this.path}/img/gifs/icon2.gif`}alt="" />
							</picture>
						</div>
						<div className={`${styles.img} ${styles.img_3}`}>
							<picture>
								<img src={`${this.path}/img/gifs/icon3.gif`}alt="" />
							</picture>
						</div>
						<div className={`${styles.img} ${styles.img_4}`}>
							<picture>
								<img src={`${this.path}/img/gifs/icon4.gif`}alt="" />
							</picture>
						</div>
					</div>
					
				</div>
				<ScrollDown />
			</div>
			{/* FACTS */}
			<div ref={this.refBody} className={styles.facts}>
				<div className={styles.container}>
				{/* <HeightSlider height={3}> */}
					<ProgressMain/>
				{/* </HeightSlider> */}
				</div>
			</div>

			{/* FAQ CARDS */}
			<div className={styles.faq}>
				<div className={styles.container}>
					<h2 className={styles.faq_title}  data-aos="fade-up">Вместе с <span>Фондом «СПИД.ЦЕНТР»</span> отвечаем на основные вопросы о вирусе иммунодефицита человека.</h2>
					<div className={styles.faq_wrap}>
						<Cards />
					</div>
				</div>
			</div>

			{/* FOOTER */}
			<FooterMain />
		</div>;
	}
}

export default Main;
