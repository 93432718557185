import React, { Component } from 'react';

// import Circles from '../../components/Circles';
import Cards from '../../components/Cards';
import PathContext from "../../context/path";
// import ScrollTop from '../../components/ScrollTop';
import Footer from '../../components/Footer';
import FooterCover from '../../components/FooterCover';
import Socials from '../../components/Socials';
import ProgressTherapy from '../../components/ProgressTherapy';
import ProgressTherapyDesktop from '../../components/ProgressTherapyDesktop';
import ScrollDown from '../../components/ScrollDown';
import HeightSlider from '../../components/HeightSlider';
import styles from './Therapy.module.sass';
// import './img/main.bg.png';

class Therapy extends Component {
	state = {
		bodyOffset: false
	}
	static contextType = PathContext;
	path = this.context;
	refWrap = React.createRef();
	scrollSlider = null;
	componentDidMount() {
		window.scrollTo(0, 0);
		this.setHeight = this.setHeight.bind(this);
		this.setHeight();
		window.addEventListener('resize', this.setHeight);
		if (window.innerWidth > 767) {
			this.scrollSlider = <div className={styles.progress}>
				<HeightSlider height={3}>
					<ProgressTherapyDesktop />
				</HeightSlider>
			</div>
		}else{
			this.scrollSlider = <div className={styles.progress}>
			<ProgressTherapy />
		</div>;
		}
		this.setState({});
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.setHeight, false);
	}
	setHeight() {
		let vh = window.innerHeight * 0.01;
		this.refWrap.current.style.setProperty('--vh', `${vh}px`);
	}

	render() {
		// let scrollSlider = <div className={styles.progress}>
		// 	<ProgressTherapy />
		// </div>;

		// if (window.innerWidth > 767) {
		// 	scrollSlider = <div className={styles.progress}>
		// 		<HeightSlider height={3}>
		// 			<ProgressTherapyDesktop />
		// 		</HeightSlider>
		// 	</div>
		// }
		return <div ref={this.refWrap} className={styles.wrap}>

			{/* BG */}
			<div className={styles.bg1}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/therapy-bg-desktop.png`} />
					<img src={`${this.path}/img/therapy-bg.png`} alt="" />
				</picture>
			</div>
			<div className={styles.bg2}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/therapy-bg2-desktop.png`} />
					<img src={`${this.path}/img/therapy-bg2.png`} alt="" />
				</picture>
			</div>
			<div className={styles.cover}>
				<div className="container">
					<h1 className={styles.title}>
						ВИЧ — это всегда смертельно опасно?
					</h1>
					<div className={styles.text}  data-aos="fade-up">

						Как работает терапия и почему она нужна
					</div>

					<div className={styles.img}>
						<img src={`${this.path}/img/gifs/icon3.gif`} alt="" />
					</div>
					<div className={styles.socials}>
						<Socials suffix={'blue'} />
					</div>
				</div>
				<ScrollDown />
			</div>
			<div className={styles.info}>
				<div className="container">
					<div  data-aos="fade-up">
					<h2>ВИЧ — это не приговор, а легко контролируемое хроническое заболевание</h2>
					<div className={styles.text_2}>
						<p>Всемирная организация здравоохра<span>-</span>нения определила ВИЧ как хроническое заболевание. С ним можно прожить полноценную и долгую жизнь, регулярно принимая терапию. </p>
						<p>Летальный исход возможен, если допустить полное ослабление организма и переход инфекции в стадию СПИД. Даже в этом случае есть шанс вернуть здоровье, если как можно раньше начать терапию.</p>
					</div>
					</div>
					<div className={styles.fact}  data-aos="fade-up">
						<div className={styles.fact_number}>72 года</div>
						<div className={styles.fact_text}>Средняя продолжительность жизни людей с ВИЧ при своевременном выявлении и лечении ВИЧ-инфекции. Столько же в среднем живут обычные россияне</div>

					</div>
					<div  data-aos="fade-up">
					<h2>Как у большинства заболеваний, у ВИЧ есть терапия</h2>
					<div className={styles.text_3}>
						<p>Пока что ВИЧ не лечится полностью, но справиться с ним помогает антиретровирусная терапия (АРВТ или АРТ).</p>
						<p>Это комбинация из нескольких лекарств, которые блокируют вирус и не дают ему размножаться.</p>
					</div>
					</div>
				</div>
			</div>
			<div>
			{this.scrollSlider}
			</div>
			<div className={styles.info_2}>
				<div className="container">
					<div  data-aos="fade-up">
					<h2>С терапией можно жить привычной жизнью</h2>
					<div className={styles.text_4}>
						<p>Если человек регулярно принимает антиретровирусную терапию и имеет неопределяемую вирусную нагрузку (ВИЧ не определяется тестами в крови), то он гарантирует себе здоровье и не может передать ВИЧ через секс своим партнерам. Да, даже если займётся сексом без презерватива. </p>
						<p>При приеме антиретровирусных препаратов можно зачать, выносить и родить здорового ребенка — риск передачи ему ВИЧ будет минимальным.</p>
					</div>
					</div>
					<div className={styles.lead}  data-aos="fade-up">Если все ВИЧ-позитивные люди начнут принимать терапию, больше никто не заболеет</div>
					<div className={styles.illustration}>
						<img src={`${this.path}/img/gifs/pers_touch.gif`} alt="" />
					</div>
					<div  data-aos="fade-up">
					<h2>Знание ВИЧ-статуса важно для ранней терапии </h2>
					<div className={styles.text_5}>
						<p>Антиретровирусная терапия дает лучшие результаты, если начать ее как можно быстрее после инфицирования.</p>
						<p>Выяснить, получил ли человек ВИЧ, можно только пройдя тестирование.</p>
					</div>
					</div>
				</div>
			</div>
			<div className={styles.faq}>
				
			<div className="container">
				<div className={styles.footer_cover}>
						<FooterCover />
					</div>
				
				<div className={styles.cards}>
					<Cards />
				</div>
				</div>
			</div>
			{/* <ScrollTop color={'blue'} /> */}
			<div className={styles.footer}>
				<Footer />
			</div>
		</div>;
	}
}

export default Therapy;
