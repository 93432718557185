
import React, { Component } from 'react';
import styles from './Footer.module.sass';
import PathContext from "../../context/path";
import ScrollTop from '../../components/ScrollTop';
import Socials from '../../components/Socials';
class Footer extends Component {


	state = {
		init: false
	};

	static contextType = PathContext;
	path = this.context;
	footer = null;
	componentDidMount() {
		if (window.innerWidth > 767) {
			this.footer = <footer className={styles.wrap}>

						<div className={styles.inner}>
							<div className={styles.header}>
								<a href="http://infografika.agency/infografika/?utm_source=hiv&utm_content=logo" className={styles.logo_info}>
									<img src={`${this.path}/img/logo-info.svg`} alt="" />
								</a>

								<a href="https://spid.center/ru/" className={styles.logo_aids}>
									<img src={`${this.path}/img/logo-aids.svg`} alt="" />
								</a>
							</div>
							<div className={styles.body}>
								<div className={styles.text}>
									<p><b>	Главная причина эпидемии ВИЧ —</b> неосведомленность, но вместе мы можем изменить ситуацию. Поделитесь этим проектом:</p></div>
								<div className={styles.socials}>

									<Socials suffix={'invert'} />
								</div>
							</div>
							<div className={styles.scroll}>
								<ScrollTop />
							</div>
						</div>

					</footer>

		} else {
			this.footer =
					<footer className={styles.wrap}>
						<div className="container">
							<div className={styles.inner}>

								<div className={styles.body}>
									<div className={styles.text}>
										<p><b>	Главная причина эпидемии ВИЧ</b> — неосведомленность, но вместе мы можем изменить ситуацию.</p>
										<p>Поделитесь этим проектом</p></div>



								</div>
								<div className={styles.socials}>

									<Socials suffix={'invert'} />
								</div>
								<div className={styles.scroll}>
									<ScrollTop />
								</div>
							</div>
						</div>
					</footer>

		}
		this.setState({});

	}
	render() {
			return (
				<>
				{this.footer}
				</>
			);
		}
	
}

export default Footer;
