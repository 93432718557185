import React, { Component } from 'react';

// import Circles from '../../components/Circles';
import Cards from '../../components/Cards';

// import ScrollTop from '../../components/ScrollTop';
import Footer from '../../components/Footer';
import FooterCover from '../../components/FooterCover';
import ProgressTalk from '../../components/ProgressTalk';
import HeightSlider from '../../components/HeightSlider';
import ScrollDown from '../../components/ScrollDown';
import Socials from '../../components/Socials';
import PathContext from "../../context/path";
import styles from './Talk.module.sass';
// import './img/main.bg.png';

class Talk extends Component {
	state = {
		bodyOffset: false
	}
	static contextType = PathContext;
	path = this.context;
	refWrap = React.createRef();

	componentDidMount() {
		window.scrollTo(0, 0);
		this.setHeight = this.setHeight.bind(this);
		this.setHeight();
		window.addEventListener('resize', this.setHeight);

	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.setHeight, false);
	}
	setHeight() {
		let vh = window.innerHeight * 0.01;
		this.refWrap.current.style.setProperty('--vh', `${vh}px`);
	}

	render() {

		let sliderHeight = 4;
		if (window.innerWidth > 767) {
			sliderHeight = 2;
		} else {
			sliderHeight = 4;
		}
		return <div ref={this.refWrap} className={styles.wrap}>

			{/* BG */}
			<div className={styles.bg1}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/talk-bg-desktop.png`} />
					<img src={`${this.path}/img/talk-bg.png`} alt="" />
				</picture>
			</div>
			<div className={styles.bg2}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/talk-bg2-desktop.png`} />
					<img src={`${this.path}/img/talk-bg2.png`} alt="" />
				</picture>
			</div>
			<div className={styles.cover}>
				<div className="container">
					<h1 className={styles.title}>
						Как говорить о ВИЧ и почему это необходимо?
					</h1>
					<div className={styles.subtitle}   data-aos="fade-up">

						Как убедить партнера пройти тестирование
					</div>
					<div className={styles.img}>
						<img src={`${this.path}/img/gifs/icon4.gif`} alt="" />
					</div>
					<div className={styles.socials}>
						<Socials suffix={'blue'} />
					</div>
				</div>
				<ScrollDown />
			</div>
			<div className={styles.info}>
				<div className="container">
					<div  data-aos="fade-up">
					<h2>Знать о вирусе — первый шаг в защите от ВИЧ</h2>
					<div className={styles.text_1}>
						<p>Когда речь заходит о предохранении или об анализах на различные инфекции, передающиеся половым путем, вы можете испытывать смущение или дискомфорт — опасаться обидеть партнера или заработать репутацию чересчур мнительного человека. </p>
						<p>В этом случае нужно опираться на заботу о своем здоровье.</p>
					</div>
					</div>
					<div className={styles.lead} data-aos="fade-up">ВИЧ стал проблемой, потому что о нем стеснялись говорить</div>
					<div data-aos="fade-up">
					<h2>Применение знаний на практике </h2>
					<div className={styles.text_2}>
						<p>«Культура справок» очень хорошо развита в Америке и странах Европы, но в России просить справку перед близостью не принято. </p>
						<p>Как предложить партнеру сделать тест, никого при этом не обидев, рекомендуют консультанты Фонда «СПИД.ЦЕНТР».</p>
					</div>
					</div>
				</div>
			</div>
			<div className={styles.progress}>
				<HeightSlider height={sliderHeight} detailProgress={true}>
					<ProgressTalk />
				</HeightSlider>
			</div>
			<div className={styles.info_2}   data-aos="fade-up">
				<div className="container">
					<div className={styles.text_3}>
						<p>
							Помните, что никто не обязан раскрывать свой ВИЧ-статус — это добровольное решение. </p>
						<p>Точно так же никто не обязан соглашаться на секс без презерватива — это тоже может быть только добровольное решение, которое мы рекомендуем принимать только с полной уверенностью в безопасности* и не забывая проходить ежегодное тестирование.
						</p>
					</div>
					<div className={styles.desc}><span>*</span>Если один из партнеров ВИЧ-положительный — при достижении неопределяемой вирусной нагрузки.</div>
				</div>
			</div>
			<div className={styles.info_3}   data-aos="fade-up">
				<div className="container">
					<h2>Узнать ВИЧ-статус анонимно и за 15 минут</h2>
					<div className={styles.text_4}>
						Сегодня ВИЧ — это легко контролируемое хроническое заболевание. С ним можно прожить полноценную и долгую жизнь. Нужно всего лишь принимать терапию, причем бесплатно — за счет государства. И чтобы остановить пандемию, нам всем нужно знать свой ВИЧ-статус и обратиться за помощью, если этот статус положительный.
					</div>

				</div>
			</div>
			<div className={styles.faq}>

				<div className="container">
				<div className={styles.footer_cover}>

					<FooterCover />

				</div>
				<div className={styles.cards}>
					<Cards />
				</div>
			</div>
		</div>

		{/* <ScrollTop color={'blue'} /> */ }
		<div className={styles.footer}>
			<Footer />
		</div>
		</div >;
	}
}

export default Talk;
