import React from 'react';
// import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import 'aos/dist/aos.css';
import './fonts/font.css';
import './index.sass';
import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}