
import React, { Component } from 'react';
import styles from './FooterMain.module.sass';
import PathContext from "../../context/path";
import Socials from '../Socials';
import ScrollTop from '../ScrollTop';
class FooterMain extends Component {
	

	state={
		init: false
	};
	static contextType = PathContext;
	path = this.context;
	render() {
		let scroll = <ScrollTop />;
		if(window.innerWidth > 767){
			scroll = null;
		}
		return (
			<>
				<footer className={styles.wrap}>
					
					<div className={styles.inner}>
						<div className={styles.header}>
							<a href="http://infografika.agency/infografika/?utm_source=hiv&utm_content=logo" className={styles.logo_info}>
									<img src={`${this.path}/img/logo-info.svg`} alt="" />
							</a>
							
							<a href="https://spid.center/ru/" className={styles.logo_aids}>
								<img src={`${this.path}/img/logo-aids.svg`} alt="" />
							</a>
						</div>
						<div className={styles.body}>
							
							<div className={styles.socials}>

								<Socials suffix={'invert'} />
							</div>
							{scroll}
						</div>
					</div>
				</footer>

			</>
		);
	}
}

export default FooterMain;
