import React, { Component } from 'react';

// import Circles from '../../components/Circles';
import PathContext from "../../context/path";
import styles from './Preloader.module.sass';
// import './img/main.bg.png';

class Preloader extends Component {
	state = {
		show: true
	};
	static contextType = PathContext;
	path = this.context;
	xDown = null;
	yDown = null;
	keyCodes = {
		UP: 38,
		DOWN: 40,
		PAGEDOWN: 34,
		PAGEUP: 33,
	};
	componentDidMount() {
		
		this.handleTouchStart = this.handleTouchStart.bind(this);
		this.handleTouchMove = this.handleTouchMove.bind(this);
		this.onMouseWheel = this.onMouseWheel.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		document.addEventListener('touchstart', this.handleTouchStart, false);
		document.addEventListener('touchmove', this.handleTouchMove, false);
		document.addEventListener('mousewheel', this.onMouseWheel);
		document.addEventListener('keydown', this.onKeyDown);
	}
	componentWillUnmount() {
		document.removeEventListener('touchstart', this.handleTouchStart, false);
		document.removeEventListener('touchmove', this.handleTouchMove, false);
		document.removeEventListener('mousewheel', this.onMouseWheel, false);
		document.removeEventListener('keydown', this.onKeyDown, false);
	}
	getTouches(evt) {
		return (
			evt.touches || // browser API
			evt.originalEvent.touches
		); // jQuery
	}
	handleTouchStart(evt) {
		const firstTouch = this.getTouches(evt)[0];
		this.xDown = firstTouch.clientX;
		this.yDown = firstTouch.clientY;
		this.setState({});
	}
	handleTouchMove(evt) {
		if (!this.xDown || !this.yDown) {
			return;
		}

		// var xUp = evt.touches[0].clientX;
		var yUp = evt.touches[0].clientY;

		// var xDiff = this.xDown - xUp;
		var yDiff = this.yDown - yUp;
		if (yDiff > 0) {
			this.hidePreload();
		}
		this.xDown = null;
		this.yDown = null;
	}
	onMouseWheel(event) {
		let delta = event.deltaY;
		if (delta < -1) {
		} else {
			this.hidePreload();
		}
	}
	onKeyDown(event) {
		let PRESSED_KEY = event.keyCode;
		if (PRESSED_KEY === this.keyCodes.UP || PRESSED_KEY === this.keyCodes.PAGEUP) {
			event.preventDefault();
		} else if (
			PRESSED_KEY === this.keyCodes.DOWN ||
			PRESSED_KEY === this.keyCodes.PAGEDOWN
		) {
			this.hidePreload();
			event.preventDefault();
		}
	}
	hidePreload(){
		window.scrollTo(0, 0);
		setTimeout(()=>{
			this.props.hidePreload();
			document.querySelector('body').style.overflow = '';
		}, 1000);
		
		this.setState({
			show: false
		});
	}
	render() {
		
		let classWrap = `${styles.wrap}`;
		if(this.state.show === true){
			classWrap = `${styles.wrap}`;
		}else{
			classWrap = `${styles.wrap} ${styles.wrap_hide}`;
		}
		return <div className={classWrap}>
			<div className={styles.bg}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/preload-bg-desktop.png`} />
					<img src={`${this.path}/img/preload-bg.png`} alt="" />
				</picture>
				
			</div>
			<div className={styles.langs}>
					<a href={`${this.path}/`} className={`${styles.lang} ${styles.lang_active}`}>RU</a >
					<a href={`${this.path}/en/`} className={styles.lang}>EN</a >
				</div>
			<div className={styles.header}>
				<div className={styles.logos}>
					<div className={styles.logo_info}>
						<img src={`${this.path}/img/logo-info-short.svg`} alt="" />
					</div>
					<div className={styles.logo_hiv}>
						<img src={`${this.path}/img/logo-aids.png`} alt="" />
					</div>
				</div>
				<div className={styles.label}>18+</div>
				
			</div>
			<div className={styles.body}>
				<div className={styles.title}>7 из 10</div>
				<div className={styles.text}>
					<p>человек получают ВИЧ через незащищенный секс.</p>
					<p>Если у вас за последние 8 лет был секс без презерватива, вы можете быть в зоне риска.</p>
				</div>
			</div>
			<div className={styles.scroll}>
				Хочу узнать почему. 
				<span>Да, я старше 18 лет</span>
				<img src={`${this.path}/img/scroll-down.svg`} alt="" />
			</div>
		</div>;
	}
}

export default Preloader;
