
import React, { Component } from 'react';
import styles from './ScrollDown.module.sass';
import PathContext from "../../context/path";
class ScrollDown extends Component {
	

	state={
		init: false
	};
	static contextType = PathContext;
	path = this.context;
	componentDidMount(){
	
		
	}
	render() {
		if(window.innerWidth > 767){
			return (
				<>
					<div className={styles.scroll_wrap}>
					<div className={styles.scroll}>
						<img src={`${this.path}/img/scroll-down.svg`} alt="" />
					</div>
					</div>

				</>
			);
		}else{
			return (
				<>
					<div className={styles.scroll}>
						<img src={`${this.path}/img/scroll-down.svg`} alt="" />
					</div>

				</>
			);
		}
	}
}

export default ScrollDown;
