import React, { Component } from "react";
import PathContext from "../../context/path";
import styles from "./Socials.module.sass";
import { withRouter } from 'react-router';
class Socials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
      copy: false
    };
  }

  // data = [
  //   {
  //     desktop: true,
  //     icon: "/img/icon/vk",
  //     href: "http://vk.com/share.php?url=https://hiv.infografika.agency${locPath}/&title=[TITLE]&description=[DESC]&image=[IMAGE]&noparse=true"
  //   },
  //   {
  //     desktop: true,
  //     icon: "/img/icon/fb",
  //     href: "https://www.facebook.com/sharer/sharer.php?u=https://hiv.infografika.agency${locPath}"
  //   },
  //   {
  //     icon: "/img/icon/ok",
  //     href: "https://connect.ok.ru/offer?url=https://hiv.infografika.agency${locPath}&title=TITLE"
  //   },
  //   {
  //     desktop: true,
  //     icon: "/img/icon/tg",
  //     href: "https://telegram.me/share/url?url=https://hiv.infografika.agency${locPath}"
  //   },
  //   {
  //     icon: "/img/icon/wa",
  //     href: "whatsapp://send?text=https://hiv.infografika.agency${locPath}"
  //   },
  //   {
  //     desktop: true,
  //     link: true,
  //     icon: "/img/icon/link",
  //     href: ""
  //   }
  // ];
  socials = [];
  static contextType = PathContext;
  path = this.context;
  componentDidMount() {
    let suffix = "";
    this.path = this.context;
    // if(this.props.invert){
    // 	suffix='-invert';
    // }
    switch (this.props.suffix) {
      case "invert":
        suffix = "-invert";
        break;
      case "blue":
        suffix = "-blue";
        break;

      default:
        break;
    }
    let locPath = this.props.location.pathname;
    
    if (window.innerWidth > 767) {
      this.socials = <>
        <a href={`https://vk.com/share.php?url=https://hiv.infografika.agency${locPath}/&noparse=true`} className={styles.link}>
              <img src={`${this.path}/img/icon/vk${suffix}.svg`} alt="" />
              <img src={`${this.path}/img/icon/vk${suffix}-hover.svg`} className={styles.img_hover} alt="" />
          </a>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=https://hiv.infografika.agency${locPath}`} className={styles.link}>
              <img src={`${this.path}/img/icon/fb${suffix}.svg`} alt="" />
              <img src={`${this.path}/img/icon/fb${suffix}-hover.svg`} className={styles.img_hover} alt="" />
          </a>
          <a href={`https://connect.ok.ru/offer?url=https://hiv.infografika.agency${locPath}`} className={styles.link}>
              <img src={`${this.path}/img/icon/ok${suffix}.svg`} alt="" />
              <img src={`${this.path}/img/icon/ok${suffix}-hover.svg`} className={styles.img_hover} alt="" />
          </a>
          <div onClick={this.showCopy.bind(this)} className={`${styles.link} ${styles.link_copy}`}>
              <img src={`${this.path}/img/icon/link${suffix}.svg`} alt="" />
              <img src={`${this.path}/img/icon/link${suffix}-hover.svg`} className={styles.img_hover} alt="" />
          </div>
      </>;
    } else{
      this.socials =  <>
          <a href={`https://vk.com/share.php?url=https://hiv.infografika.agency${locPath}/&noparse=true`} className={styles.link}>
              <img src={`${this.path}/img/icon/vk${suffix}.svg`} alt="" />
          </a>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=https://hiv.infografika.agency${locPath}`} className={styles.link}>
              <img src={`${this.path}/img/icon/fb${suffix}.svg`} alt="" />
          </a>
        <a href={`https://connect.ok.ru/offer?url=https://hiv.infografika.agency${locPath}`} className={styles.link}>
              <img src={`${this.path}/img/icon/ok${suffix}.svg`} alt="" />
          </a>
        <a href={`https://telegram.me/share/url?url=https://hiv.infografika.agency${locPath}`} className={styles.link}>
              <img src={`${this.path}/img/icon/tg${suffix}.svg`} alt="" />
          </a>
        <a href={`whatsapp://send?text=https://hiv.infografika.agency${locPath}`} className={styles.link}>
              <img src={`${this.path}/img/icon/wa${suffix}.svg`} alt="" />
          </a>
        <div onClick={this.showCopy.bind(this)} className={`${styles.link} ${styles.link_copy}`}>
              <img src={`${this.path}/img/icon/link${suffix}.svg`} alt="" />
          </div>
          </>;
    }
   

    // this.socials = this.data.map((item, index) => {
    //   if (window.innerWidth > 768) {
    //     if (item.desktop) {
    //       return (
    //         <a href={item.href} key={index} className={styles.link}>
    //           <img src={`${this.path}${item.icon}${suffix}.svg`} alt="" />
    //         </a>
    //       );
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     return (
    //       <a href={item.href} key={index} className={styles.link}>
    //         <img src={`${this.path}${item.icon}${suffix}.svg`} alt="" />
    //       </a>
    //     );
    //   }
    // });
    this.setState({
      init: true,
    });
  }
  showCopy(){
    let link = window.location;
    navigator.clipboard.writeText(link)
    .then(() => {
      // this.setState({
      //   copy: true
      // }, ()=>{
      //   setTimeout(()=>{
      //     this.setState({
      //       copy: false
      //     });
      //   }, 500);
      // });
    })
    .catch(err => {
      console.log('Ошибка в копировании ссылки', err);
    });
    
  }
  render() {
    
    // let copyClass = `${styles.copy}`;
    // if(this.state.copy === true){
    //   copyClass = `${styles.copy} ${styles.copy_show}`;
    // }else{
    //   copyClass = `${styles.copy}`;
    // }
    return (
      <>
        {/* <div className={copyClass}>Ссылка скопирована</div> */}
        <div className={styles.wrap}>{this.socials}</div>
      </>
    );
  }
}

export default withRouter(Socials);
