
import React, { Component } from 'react';
import styles from './ProgressTalk.module.sass';
import PathContext from "../../context/path";
class ProgressTalk extends Component {


	state = {
		opened: false,
		offset: 0
	};

	refItems = React.createRef();
	refInner = React.createRef();

	static contextType = PathContext;
	path = this.context;

	componentDidMount() {
		if(window.innerWidth > 767){
			
			this.setState({
				offset: this.refItems.current.offsetWidth - this.refInner.current.offsetWidth 
			})
		}else{
		this.setState({
			offset: this.refItems.current.offsetWidth - window.innerWidth
		})
	}

	}
	componentWillUnmount() {
		// window.removeEventListener('scroll', this.setOffset.bind(this));
		if(window.innerWidth > 767){
			this.setState({
				offset: this.refItems.current.offsetWidth - this.refInner.current.offsetWidth 
			})
		}else{
		this.setState({
			offset: this.refItems.current.offsetWidth - window.innerWidth
		})
	}
	}

	// checkData(stage){


	// }
	// setOffset(){
	// 	console.log(this.state.offset);
	// 	console.log(this.props.progress);

	// 	document.querySelector('#talk-items').style.left = `-${this.state.offset * (this.props.progress / 100)}px`;

	// }
	render() {
		// document.querySelector('#talk-items').style.left = `-${this.state.offset * (this.props.progress / 100)}px`;
		let progress = this.props.progress;
		return (
			<>
				
				<div className={styles.wrap}>
				<div className={styles.inner} ref={this.refInner} >
					<div className={styles.items} ref={this.refItems} style={{ left: `-${this.state.offset * (progress / 100)}px` }}>
						<div className={styles.item}>
							<div className={styles.img}>
								<img src={`${this.path}/img/talk-img-1.png`} alt="" />
							</div>
							<div className={styles.title}>Узнайте мнение человека о проблеме</div>
							<div className={styles.text}>
								<p>Перед тем как завести разговор о проверке ВИЧ-статуса, узнайте у партнера, что он думает об этом вирусе. </p>
								<p>Предложите прочитать эту статью, обсудить статистику или поделиться своим мнением. Поделитесь знанием принципа «Неопределяемый = Непередающийся», благодаря которому вы сможете планировать семью даже если у кого-то из партнеров будет ВИЧ.</p>
								<p>А затем переходите к сути: «Может, и нам стоит провериться?»</p>
							</div>
						</div>
						<div className={styles.item}>
							<div className={styles.img}>
								<img src={`${this.path}/img/talk-img-2.png`} alt="" />
							</div>
							<div className={styles.title}>Предложите сдать анализы вместе</div>
							<div className={styles.text}>
								<p>Если хотите убедить своего партнера пройти тестирование, будьте готовы и сами сдать анализы. Не нужно говорить: «А что я? И так видно, что у меня все нормально» или «У тебя было больше половых партнеров». </p>
								<p>Скажите, что неплохо бы проверить свое здоровье, и ненавязчиво пригласите составить вам компанию. Частая причина отказа — страх узнать свои результаты. Но вместе это сделать легче, а зная, что ВИЧ больше не опасен ни для жизни, ни для семьи — тем более.</p>
							</div>
						</div>
						<div className={styles.item}>
							<div className={styles.img}>
								<img src={`${this.path}/img/talk-img-3.png `} alt="" />
							</div>
							<div className={styles.title}>Расскажите о своем опыте</div>
							<div className={styles.text}>
								<p>Поделитесь своими планами сдать анализы, прежде чем завести разговор о тестировании. Покажите, что вы действительно интересуетесь своим здоровьем и в этой процедуре нет ничего страшного.</p>
								<p>Если вы недавно проходили тестирование — расскажите, как все происходило, и предложите поддержку. </p>
								<p>Если с тех пор прошло больше года — можно сделать тест повторно.</p>
							</div>
						</div>
						<div className={styles.item}>
							<div className={styles.img}>
								<img src={`${this.path}/img/talk-img-4.png`} alt="" />
							</div>
							<div className={styles.title}>Убедите, что доверие ни при чём</div>
							<div className={styles.text}>
								<p>В ответ на предложение узнать ВИЧ-статус, партнеру может показаться, что вы сомневаетесь в его любви, чистоте и преданности. Ваша задача — убедить его, что дело в заботе о вашем будущем.</p>
								<p>Расскажите о том, что секс является одним из основных способов передачи ВИЧ, а вирус в течение многих лет может никак себя не проявлять. Не все люди имеют привычку заботиться о своем здоровье, даже если знают о заболеваниях, передающихся половым путем.</p>
							</div>
						</div>
						{/* <div className={styles.item}>
							<div className={styles.img}>
								<img src={`${this.path}/img/talk-img-5.png`} alt="" />
							</div>
							<div className={styles.title}>Не манипулируйте и не поддавайтесь на манипуляции</div>
							<div className={styles.text}>
								<p>Вы не имеете права заставлять партнера сдать анализ. Ни шантажом, ни угрозами о разрыве. Но и ваша половина должна считаться с вашими правами и желаниями.</p>
								<p>Не позволяйте склонить себя к незащищенному сексу. Даже если у вас уже был контакт без презерватива, вы могли не заразиться. И вновь подвергать себя опасности точно не стоит.</p>
								<p>Сдавать вашему партнёру анализы или нет — это его выбор. Принять это или нет — выбор ваш.</p>
							</div>
						</div> */}
					</div>
					</div>
				</div>
			</>
		);
	}
}

export default ProgressTalk;
