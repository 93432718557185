
import React, { Component } from "react";

import { Helmet } from "react-helmet";
import AOS from 'aos';
import Header from './components/Header';
import Therapy from './pages/Therapy';
import Main from './pages/Main';
import Feel from './pages/Feel';
import Risk from './pages/Risk';
import Test from './pages/Test';
import Talk from './pages/Talk';
import About from './pages/About';
import Preloader from './pages/Preloader';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// import { createBrowserHistory } from "history";
import PathContext from "./context/path";

import './App.sass';

// const PathContext = React.createContext('/demo/hiv');
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preload: false
    }
  }
  preloader = null;
  desktop = null;
  componentDidMount() {
    
    window.scrollTo(0, 0);
    window.onload = () => {
      setTimeout(() => {
        document.querySelector('body').style.opacity = 1;
        
          AOS.init({
            once: true,
            duration: 800,
            offset: 50
          });
        
       
      }, 900);
    }
    if (window.location.pathname === '/') {
      document.querySelector('body').style.overflow = 'hidden';
      this.preloader = <Preloader hidePreload={this.hidePreload} />;
    } else {
      this.preloader = null;
    }
    this.setState({});
    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 768) {
    //   // код для мобильных устройств
    // } else {
    //   // код для обычных устройств
      
    //   // document.querySelector('.wrap').innerHTML = '';
    //   this.desktop = <div className="placeholder">
    //     <div className="placeholder_bg">
    //       <img src={`/img/placeholder.png`} alt="" />
    //     </div>
    //     <div className="placeholder_inner">
    //       <div className="placeholder_header">
    //         <h1><span>ВИЧ</span><br /> Ответы, которые вы<br /> (не) знали</h1>
    //         <div className="placeholder_logo">
    //           <img src={`/img/placeholder_logo.svg`} alt="" />
    //         </div>
    //       </div>
    //       <div className="placeholder_body">
    //         <div className="placeholder_qr">
    //           <img src={`/img/placeholder_qr.png`} alt="" />
    //           <span>Пожалуйста, откройте проект<br />на мобильном телефоне</span>
    //         </div>
    //         <div className="placeholder_img">
    //           <img src={`/img/placeholder_img.png`} alt="" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>;
    // }


  }


  hidePreload = () => {
    this.preloader = null;
    this.setState({
      preload: true
    });
  };
  render() {

    // const basename = '/demo/hiv';
    const basename = '';
    return (
      <PathContext.Provider value={basename}>
        {/* {this.desktop} */}
        <div className="wrap" >

          <Router basename={basename} >

            <Header />
            <Switch>
              <Route path="/about">
                <Helmet placement='top'>
                  <meta name="twitter:title" content="Вирус, о котором не принято говорить в России" />
                  <meta name="twitter:description" content="1 122 879 человек с подтвержденным положительным ВИЧ-статусом живут сегодня  в России. Посмотрите наш спецпроект по ссылке" />
                  <meta name="twitter:image" content="https://hiv.infografika.agency/img/og-image.jpg" />
                  <meta property="og:title" content="Вирус, о котором не принято говорить в России" />
                  <meta property="og:image" content="https://hiv.infografika.agency/img/og-image.jpg" />
                  <meta property="og:image:secure_url" content="https://hiv.infografika.agency/img/og-image.jpg" />
                  <meta property="og:url" content="https://hiv.infografika.agency/" />
                  <meta property="og:description" content="1 122 879 человек с подтвержденным положительным ВИЧ-статусом живут сегодня  в России. Посмотрите наш спецпроект по ссылке" />

                  <link rel="alternate" hreflang="x-default" href="https://hiv.infografika.agency/about/" />
                  <link rel="alternate" hreflang="en" href="https://hiv.infografika.agency/en/about/" />

                </Helmet>
                <About />
              </Route>
              <Route path="/test">
                <Helmet placement='top'>
                  <meta name="twitter:title" content="Тест. Проверьте свои знания о ВИЧ." />
                  <meta name="twitter:description" content="7 из 10 человек получают ВИЧ через незащищенный секс." />
                  <meta name="twitter:image" content="https://hiv.infografika.agency/img/og-image5.jpg" />
                  <meta property="og:title" content="Тест. Проверьте свои знания о ВИЧ." />
                  <meta property="og:image" content="https://hiv.infografika.agency/img/og-image5.jpg" />
                  <meta property="og:image:secure_url" content="https://hiv.infografika.agency/img/og-image5.jpg" />
                  <meta property="og:description" content="7 из 10 человек получают ВИЧ через незащищенный секс." />
                  <meta property="og:url" content="https://hiv.infografika.agency/test" />

                  <link rel="alternate" hreflang="x-default" href="https://hiv.infografika.agency/test/" />
                  <link rel="alternate" hreflang="en" href="https://hiv.infografika.agency/en/test/" />

                </Helmet>
                <Test />
              </Route>
              <Route path="/talk">
                <Helmet placement='top'>
                  <meta name="twitter:title" content="Как говорить о ВИЧ и почему это необходимо?" />
                  <meta name="twitter:description" content="Как убедить партнера пройти тестирование" />
                  <meta name="twitter:image" content="https://hiv.infografika.agency/img/og-image4.jpg" />
                  <meta property="og:title" content="Как говорить о ВИЧ и почему это необходимо?" />
                  <meta property="og:image" content="https://hiv.infografika.agency/img/og-image4.jpg" />
                  <meta property="og:image:secure_url" content="https://hiv.infografika.agency/img/og-image4.jpg" />
                  <meta property="og:description" content="Как убедить партнера пройти тестирование" />
                  <meta property="og:url" content="https://hiv.infografika.agency/talk" />

                  <link rel="alternate" hreflang="x-default" href="https://hiv.infografika.agency/talk/" />
                  <link rel="alternate" hreflang="en" href="https://hiv.infografika.agency/en/talk/" />

                </Helmet>
                <Talk />
              </Route>
              <Route path="/therapy">
                <Helmet placement='top'>
                  <meta name="twitter:title" content="ВИЧ — это всегда смертельно опасно?" />
                  <meta name="twitter:description" content="Как работает терапия и почему она нужна" />
                  <meta name="twitter:image" content="https://hiv.infografika.agency/img/og-image3.jpg" />
                  <meta property="og:title" content="ВИЧ — это всегда смертельно опасно?" />
                  <meta property="og:image" content="https://hiv.infografika.agency/img/og-image3.jpg" />
                  <meta property="og:image:secure_url" content="https://hiv.infografika.agency/img/og-image3.jpg" />
                  <meta property="og:description" content="Как работает терапия и почему она нужна" />
                  <meta property="og:url" content="https://hiv.infografika.agency/therapy" />

                  <link rel="alternate" hreflang="x-default" href="https://hiv.infografika.agency/therapy/" />
                  <link rel="alternate" hreflang="en" href="https://hiv.infografika.agency/en/therapy/" />

                </Helmet>
                <Therapy />
              </Route>
              <Route path="/risk">
                <Helmet placement='top'>
                  <meta name="twitter:title" content="Пути передачи ВИЧ и как себя обезопасить" />
                  <meta name="twitter:description" content="За последние 20 лет ВИЧ перестал был проблемой маргиналов" />
                  <meta name="twitter:image" content="https://hiv.infografika.agency/img/og-image2.jpg" />
                  <meta property="og:title" content="Пути передачи ВИЧ и как себя обезопасить" />
                  <meta property="og:image" content="https://hiv.infografika.agency/img/og-image2.jpg" />
                  <meta property="og:image:secure_url" content="https://hiv.infografika.agency/img/og-image2.jpg" />
                  <meta property="og:description" content="За последние 20 лет ВИЧ перестал был проблемой маргиналов" />
                  <meta property="og:url" content="https://hiv.infografika.agency/risk" />

                  <link rel="alternate" hreflang="x-default" href="https://hiv.infografika.agency/risk/" />
                  <link rel="alternate" hreflang="en" href="https://hiv.infografika.agency/en/risk/" />

                </Helmet>
                <Risk />
              </Route>
              <Route path="/feel">
                <Helmet placement='top'>
                  <meta name="twitter:title" content="Я чувствую себя хорошо. Я могу быть болен?" />
                  <meta name="twitter:description" content="Как развивается вирус: симптомы и возможные признаки" />
                  <meta name="twitter:image" content="https://hiv.infografika.agency/img/og-image1.jpg" />
                  <meta property="og:title" content="Я чувствую себя хорошо. Я могу быть болен?" />
                  <meta property="og:image" content="https://hiv.infografika.agency/img/og-image1.jpg" />
                  <meta property="og:image:secure_url" content="https://hiv.infografika.agency/img/og-image1.jpg" />
                  <meta property="og:description" content="Как развивается вирус: симптомы и возможные признаки" />
                  <meta property="og:url" content="https://hiv.infografika.agency/feel" />

                  <link rel="alternate" hreflang="x-default" href="https://hiv.infografika.agency/feel/" />
                  <link rel="alternate" hreflang="en" href="https://hiv.infografika.agency/en/feel/" />


                </Helmet>
                <Feel />
              </Route>
              <Route path="/">
                <Helmet placement='top'>
                  <meta name="twitter:title" content="Вирус, о котором не принято говорить в России" />
                  <meta name="twitter:description" content="1 122 879 человек с подтвержденным положительным ВИЧ-статусом живут сегодня  в России. Посмотрите наш спецпроект по ссылке" />
                  <meta name="twitter:image" content="https://hiv.infografika.agency/img/og-image.jpg" />
                  <meta property="og:title" content="Вирус, о котором не принято говорить в России" />
                  <meta property="og:image" content="https://hiv.infografika.agency/img/og-image.jpg" />
                  <meta property="og:image:secure_url" content="https://hiv.infografika.agency/img/og-image.jpg" />
                  <meta property="og:url" content="https://hiv.infografika.agency/" />
                  <meta property="og:description" content="1 122 879 человек с подтвержденным положительным ВИЧ-статусом живут сегодня  в России. Посмотрите наш спецпроект по ссылке" />

                  <link rel="alternate" hreflang="x-default" href="https://hiv.infografika.agency/" />
                  <link rel="alternate" hreflang="en" href="https://hiv.infografika.agency/en/" />


                </Helmet>
                {/* <div> */}
                <div>
                  {this.preloader}
                </div>
                {/* </div> */}
                <Main preloader={this.state.preload} />
              </Route>
            </Switch>
          </Router>
        </div>
      </PathContext.Provider>
    );
  }
}
