import React, { Component } from 'react';
import PathContext from "../../context/path";
import Cards from '../../components/Cards';
import ProgressFeel from '../../components/ProgressFeel';
import HeightSlider from '../../components/HeightSlider';
import Footer from '../../components/Footer';
import FooterCover from '../../components/FooterCover';
import Socials from '../../components/Socials';
// import ScrollTop from '../../components/ScrollTop';
import ScrollDown from '../../components/ScrollDown';
import styles from './Feel.module.sass';
class Feel extends Component {
	state = {}
	static contextType = PathContext;
	path = this.context;
	
	refWrap = React.createRef();
	componentDidMount() {

		window.scrollTo(0, 0);
		
		this.setHeight = this.setHeight.bind(this);
		this.setHeight();
		window.addEventListener('resize', this.setHeight);

	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.setHeight, false);
	}
	setHeight() {
		let vh = window.innerHeight * 0.01;
		this.refWrap.current.style.setProperty('--vh', `${vh}px`);
	}

	render() {
		return <div ref={this.refWrap} className={styles.wrap} >

			{/* BG */}
			<div className={styles.bg1}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/feel-bg-desktop.png`} />
					<img src={`${this.path}/img/feel-bg.png`} alt="" />
				</picture>
			</div>
			<div className={styles.bg2}>
				<picture>
					<source media="(min-width: 768px)" srcSet={`${this.path}/img/feel-bg2-desktop.png`} />
					<img src={`${this.path}/img/feel-bg2.png`} alt="" />
				</picture>
			</div>
			{/* cover */}
			<div className={styles.cover}>
				<div className="container">
					<h1 className={styles.title} >
						Я чувствую себя хорошо. Я могу быть болен?
					</h1>
					<div className={styles.text} data-aos="fade-up">
						Как развивается вирус: симптомы и возможные признаки
					</div>
					<div className={styles.img}>
						{/* <img src={`${this.path}/img/feel-cover.png`} alt="" /> */}
						<img src={`${this.path}/img/gifs/icon2.gif`} loading='lazy' alt="" />
					</div>
					<div className={styles.socials}>
					<Socials suffix={'blue'}/>
					</div>
				</div>
				<ScrollDown />
			</div>
			<div className={styles.progress}>
				<HeightSlider height={10}>
					<ProgressFeel />
				</HeightSlider>
			</div>
			<div className="container">
				<div className={styles.text_2} data-aos="fade-up">
					К счастью, сейчас такое случается все реже: благодаря применению антиретровирусной терапии на ранней стадии ВИЧ не прогрессирует и СПИД не развивается.
				</div>
			</div>
			<div className={styles.faq}>
				<div className="container">
					<div  data-aos="fade-up">
					<h2 className={styles.faq_title}>Человек с ВИЧ ничем не отличается от вас</h2>
					<div className={styles.faq_text}>
						<p>Людей с положительным ВИЧ-статусом не определить по внешним признакам.</p>
						<p>
							<span>По симптомам, особенно на ранней стадии, диагностировать ВИЧ-инфекцию тоже нельзя. </span>
							<span>Но можно сдать тест, чтобы избавиться от неопределенности и тревожности.</span>
						</p>
					</div>
					</div>
					<div className={styles.footer_cover}>
						<FooterCover />
					</div>
					<div className={styles.cards}>

						<Cards />
					</div>

				</div>
			</div>
			{/* <ScrollTop color={'blue'}/> */}
			<div className={styles.footer}>
				<Footer />
			</div>
		</div>;
	}
}

export default Feel;
