
import React, { Component } from 'react';
import styles from './ScrollTop.module.sass';
import PathContext from "../../context/path";
import {ReactComponent as ReactSvg} from './arrow-top.svg';
class ScrollTop extends Component {
	

	state={
		init: false
	};
	text = 'В начало';
	static contextType = PathContext;
	path = this.context;
	componentDidMount(){
		// if(window.innerWidth > 768){
		// 	this.text = 'В начало раздела';
		// }
		this.setState({
			init: true
		});
	}
	scrollTop(){
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
		
	}
	render() {
		let style = `${styles.wrap}`;
		if(this.props.color === 'blue'){
			style = `${styles.wrap} ${styles.wrap_blue}`;
		}
		return (
			<>
				<div className={style} onClick={this.scrollTop.bind(this)}>
					{/* <img src="./img/arrow-top.svg" alt="" /> */}
					<div className={styles.svg}>

						<ReactSvg />
						<img src={`${this.path}/img/icon/arrow-top-invert.svg`}  className={styles.svg_second} alt="" />
					</div>
					{this.text}
				</div>

			</>
		);
	}
}

export default ScrollTop;
